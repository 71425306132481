@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.gallery-block {
    width: 100%;
    margin-bottom: 162px;
    gap: 46px;
    @include mixins.flex(column, center, center);

    @media (max-width: 850px) {
        margin-bottom: 66px;
        gap: 40px;
    }

    .gallery-block-image-container {
        height: 300px;

        @media (max-width: 950px) {
            height: 250px;
        }

        img {
            height: 100%;
            width: auto;
            margin: 0 6px;
        }
    }

    .gallery-block-text-container {
        width: calc(100% - 80px);
        max-width: 1200px;
        color: $black;
        @include mixins.flex(row, space-between, flex-start);

        @media (max-width: 950px) {
            gap: 32px;
            @include mixins.flex(column, space-between, flex-start);
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
        }

        .gallery-block-title {
            
            span {
                display: block;
                margin-bottom: 26px;
                @include mixins.font(Forum, 22px, normal, 400, 120%);

                @media (max-width: 950px) {
                    margin-bottom: 16px;
                }
            }

            h3 {
                width: 400px;
                margin: 0;
                text-transform: uppercase;
                @include mixins.font(Playfair, 60px, normal, 400, 90%);

                @media (max-width: 500px) {
                    width: 270px;
                    @include mixins.font(Playfair, 40px, normal, 400, 90%);
                }
            }
        }

        .gallery-block-description {
            width: 400px;
            @include mixins.font(Forum, 22px, normal, 400, 120%);

            @media (max-width: 950px) {
                width: 100%;
            }

            @media (max-width: 500px) {
                @include mixins.font(Forum, 16px, normal, 400, 120%);
            }
        }
    }
}