@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';


.program-block {
    width: 100%;
    margin-bottom: 192px;
    color: $black;
    @include mixins.flex(row, center, center);

    @media (max-width: 850px) {
        margin-bottom: 96px;
    }

    .program-block-container {
        width: 100%;
        max-width: 1200px;
        @include mixins.flex(column, flex-start, center);

        @media (max-width: 1260px) {
            width: calc(100% - 40px);
            margin: auto 20px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
            margin: auto 16px;
        }

        .program-block-title-box {
            width: 100%;
            margin-bottom: 57px;
            @include mixins.flex(row, space-between, flex-end);

            @media (max-width: 850px) {
                margin-bottom: 40px;
                @include mixins.flex(column, flex-start, flex-start);
            }


            h3 {
                width: 40%;
                margin: 0;
                text-transform: uppercase;
                @include mixins.font(Playfair, 90px, normal, 400, 80%);
    
                @media (max-width: 850px) {
                    width: 100%;
                    margin: 0 0 20px 0;
                    @include mixins.font(Playfair, 40px, normal, 400, 120%);
                }
            }

            .program-block-tabs-box {
                gap: 24px;
                @include mixins.flex(row, space-between, center);

                @media (max-width: 850px) {
                    gap: unset;
                    @include mixins.flex(column, flex-start, flex-start);
                }

                span {
                    width: 56px;
                    @include mixins.font(Forum, 18px, normal, 400, 100%);

                    @media (max-width: 850px) {
                        width: unset;
                        margin-bottom: 8px;
                    }
                }

                .program-block-tabs-box-btns {
                    gap: 12px;
                    @include mixins.flex(row, space-between, flex-end);

                    @media (max-width: 850px) {
                        gap: 4px;
                    }

                    button {
                        height: 48px;
                        padding: 0 24px;
                        background-color: $transparent;
                        outline: none;
                        border-radius: 1000px;
                        border: 1px solid $block-color;
                        color: $block-color;
                        cursor: pointer;
                        transition: all 0.4s ease-in-out;
                        @include mixins.font(Forum, 16px, normal, 400, 120%);

                        &:hover {
                            background-color: $block-color;
                            color: $main-color;
                        }

                        &.active {
                            background-color: $block-color;
                            color: $main-color;
                        }
                    }
                }
            }
        }

        .program-block-info-box {
            width: 100%;
            gap: 40px;
            @include mixins.flex(column, flex-start, center);

            @media (max-width: 500px) {
                gap: 32px;
            }
        }
    }
}