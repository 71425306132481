@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.welcome-section {
    width: 100%;
    overflow: hidden;
    margin-bottom: 192px;
    padding-top: 87px;
    color: $block-color;
    @include mixins.flex(row, center, center);

    @media (max-width: 850px) {
        margin-bottom: 96px;
    }

    @media (max-width: 500px) {
        padding-top: 78px;
    }

    .welcome-section-container {
        width: 100%;
        height: 100%;
        max-width: 1200px;
        @include mixins.flex(column, flex-start, center);

        @media (max-width: 1260px) {
            width: calc(100% - 40px);
            margin: auto 20px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
            margin: auto 16px;
        }

        .welcome-section-container-text-block {
            width: 100%;
            margin-bottom: 36px;
            @include mixins.flex(column, space-between, center);

            @media (max-width: 1200px) {
                margin-bottom: 56px;
                @include mixins.flex(column, flex-start, center);
            }
            
            @media (max-width: 880px) {
                margin-bottom: 16px;
                padding-top: 50px;
            }
        }
    }
}



// Styles For Main Text

.welcome-section-text {
    width: 80%;
    margin-top: 87px;
    margin-bottom: 32px;
    position: relative;
    flex-wrap: wrap;
    @include mixins.flex(row, center, center);

    @media (max-width: 1200px) {
       width: 90%;
       @include mixins.flex(row, center, center);
    }

    @media (max-width: 1060px) {
        width: 100%;
        @include mixins.flex(row, center, center);
    }

    // @media (max-width: 960px) {
    //     @include mixins.flex(row, space-between, center);
    // }

    @media (max-width: 880px) {
        margin-top: -22px;
        @include mixins.flex(column, flex-start, center);
    }

    @media (max-width: 450px) {
        margin-top: 47px;
        @include mixins.flex(column, flex-start, flex-start);
    }

    .welcome-section-image {
        width: 652px;

        @media (max-width: 800px) {
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    .welcome-section-butterfly-image {
        width: 137px;
        position: absolute;
        top: -60px;
        left: 50px;

        @media (max-width: 950px) {
            left: 0px;
        }

        @media (max-width: 800px) {
            width: 100px;
            top: -30px;
            left: -40px;
        }

        @media (max-width: 650px) {
            width: 70px;
            left: -40px;
            top: 0;
        }

        @media (max-width: 500px) {
            top: -20px;
        }

        img {
            width: 100%;
        }
    }

    .welcome-section-first-text-box {
        width: 100%;

        @media (max-width: 880px) {
            margin-bottom: 5px;
            order: 2;
            @include mixins.flex(column, center, center);
        }

        h1 {
            color: $block-color;
            margin: 0 0 0 5px;
            position: relative;
            z-index: 5;
            @include mixins.font(Forum, 18px, normal, 400, 110%);

            @media (max-width: 880px) {
                margin: 0 0 0 0;
                @include mixins.font(Forum, 12px, normal, 400, 100%);
            }

            @media (max-width: 450px) {
                width: 100%;
                text-align: start;
            }
        }

        span {
            text-transform: uppercase;
            position: relative;
            z-index: 5;
            @include mixins.font(Forum, 100px, normal, 400, 110%);

            @media (max-width: 880px) {
                margin: 0 0 0 0;
                @include mixins.font(Forum, 55px, normal, 400, 120%);
            }

            @media (max-width: 450px) {
                width: 100%;
                text-align: start;
            }
        }
    }

    a {
        @media (max-width: 880px) {
            order: 6;
        }
    }

    .welcome-section-second-text {
        width: 236px;
        margin: 0;
        position: relative;
        z-index: 5;
        @include mixins.font(Forum, 22px, normal, 400, 110%);

        @media (max-width: 880px) {
            width: 266px;
            margin: 0 0 33px 0;
            text-align: center;
            order: 5;
            @include mixins.font(Forum, 18px, normal, 400, 120%);
        }

        @media (max-width: 450px) {
            align-self: flex-start;
        }
    }

    .welcome-section-third-text {
        text-transform: uppercase;
        margin: 0 0 0 45px;
        position: relative;
        z-index: 5;
        @include mixins.font(Forum, 100px, normal, 400, 100%);

        &.zIndex {
            z-index: 1;
        }

        @media (max-width: 880px) {
            margin: 0 0 10px 0;
            order: 3;
            @include mixins.font(Forum, 55px, normal, 400, 50%);
        }

        @media (max-width: 450px) {
            width: 100%;
            text-align: end;
        }
    }

    .welcome-section-fourth-text {
        margin: 0 145px 0 0;
        position: relative;
        z-index: 5;
        @include mixins.font(Noto Serif Display, 100px, italic, 300, 110%);

        @media (max-width: 960px) {
            margin: 0 0 0 128px;
        }

        @media (max-width: 880px) {
            margin: 0 0 13px 0;
            order: 4;
            @include mixins.font(Noto Serif Display, 55px, italic, 300, 100%);
        }

        @media (max-width: 450px) {
            width: 100%;
            text-align: start;
        }
    }

    // .welcome-section-key-image {
    //     margin: 12px 0 0 57px;

    //     @media (max-width: 960px) {
    //         position: absolute;
    //         right: -53px;
    //         bottom: 0px;
    //     }

    //     @media (max-width: 880px) {
    //         width: 139px;
    //         position: relative;
    //         right: 0px;
    //         margin: 0 0 19px 0;
    //         order: 1;
    //     }
    // }

    .welcome-section-key-image {
        position: absolute;
        top: -135px;
        left: 450px;   
        z-index: 2;
        pointer-events: none;

        @media (max-width: 1000px) {
            left: unset;
            right: 190px;
        }

        @media (max-width: 880px) {
            width: 250px;
            left: unset;
            right: 0px;
            top: -60px;
        }

        @media (max-width: 600px) {
            width: 170px;
            right: -60px;
            top: -50px;
        }

        @media (max-width: 450px) {
            width: 190px;
            right: -70px;
            top: -95px;
        }
    }

    .welcome-section-key-video {
        width: 620px;
        height: auto;
        position: absolute;
        top: -175px;
        left: 300px;
        z-index: 2;
        transform: rotate(15deg);
        pointer-events: none;

        @media (max-width: 1000px) {
            left: 180px;
        }

        @media (max-width: 880px) {
            width: 500px;
            top: -100px;
            left: unset;
            right: -200px;
        }

        @media (max-width: 500px) {
            width: 370px;
            right: -160px;
        }
    }
}

// Styles For Button

.welcome-section-button {
    width: 273px;
    height: 82px;
    margin: 0px 0 16px 0px;
    background-color: $transparent;
    outline: none;
    border-radius: 1000px;
    border: 1px solid $block-color;
    color: $block-color;
    position: relative;
    z-index: 5;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    @include mixins.font(Forum, 22px, normal, 400, 110%);

    &:hover {
        background-color: $block-color;
        color: $main-color;
    }

    // &.safari {
    //     position: absolute;
    //     top: 20px;
    //     right: 0;

    //     @media (max-width: 880px) {
    //         position: static;
    //         width: 241px;
    //         height: 74px;
    //         margin: 0 0 0 0;
    //         order: 6;
    //     }
    // }

    // @media (max-width: 1000px) {
    //     position: absolute;
    //     top: 20px;
    //     right: 0;
    //     z-index: 5;
    // }

    @media (max-width: 880px) {
        position: relative;
        width: 241px;
        height: 74px;
        margin: 48px 0 16px 0;
        order: 6;
    }
}


// Styles For Contact Link

.welcome-section-contact {
    overflow: hidden;
    @include mixins.font(Forum, 18px, normal, 400, 110%);

    @media (max-width: 500px) {
        margin-bottom: 54px;
    }

    span {
        display: block;
        color: $block-color;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }

    div {
        width: 100%;
        height: 1px;
        background-color: $block-color;
        opacity: 0.1;
        transform: translateX(-100%);
        transition: transform 0.4s, opacity 0.7s;
    }
}

.welcome-section-contact:hover div {
    transform: translateX(0);
    opacity: 1;
}

.welcome-section-contact:hover span {
    color: $block-color;
}


// Styles For Info Text

.welcome-section-info {
    margin: 0px 0px 48px 0;
    gap: 64px;
    position: relative;
    z-index: 5;
    @include mixins.flex(row, flex-start, flex-start);

    @media (max-width: 1200px) {
        // margin: 40px 0 0 0;
        gap: 64px;
        @include mixins.flex(row, space-between, flex-start);
    }

    @media (max-width: 880px) {
        margin: 38px 0 0 0;
    }

    @media (max-width: 600px) {
        width: 100%;
        gap: 0;
    }

    @media (max-width: 500px) {
        order: 3;
    }

    .welcome-section-info-box {
        @include mixins.flex(column, flex-start, flex-start);

        span:first-child {
            @include mixins.font(Forum, 16px, normal, 400, 120%);

            @media (max-width: 1200px) {
                @include mixins.font(Forum, 18px, normal, 400, 120%);
            }

            @media (max-width: 600px) {
                @include mixins.font(Forum, 12px, normal, 400, 120%);
            }
        }

        span:last-child {
            white-space: nowrap;
            color: $block-color;
            @include mixins.font(Forum, 32px, normal, 400, 110%);

            @media (max-width: 1200px) {
                @include mixins.font(Forum, 34px, normal, 400, 120%);
            }

            @media (max-width: 600px) {
                @include mixins.font(Forum, 22px, normal, 400, 120%);
            }
        }
    }
}


// Styles For Main Photo

.welcome-section-photo {
    width: 100%;
    height: 486px;
    background-image: url('../../images/main-photo.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0% 40%;
    position: relative;
    z-index: 5;

    @media (max-width: 960px) {
        height: 338px;
    }

    @media (max-width: 600px) {
        height: 248px;
    }
}