// Colors

$main-color: #3C2C13;
$block-color: #F5EDE5;
$line-color: rgba(255, 249, 242, 0.10);
$accent-color: #D7551C;
$accent-color-opacity: #E6A180;
$transparent: transparent;
$white: #FFFDFB;
$black: #F5EDE5;
$brown: #4E3D21;
$black-opacity: rgba(48, 48, 48, 0.5);

