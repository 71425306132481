@use '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.price-item-extra {
    width: calc(100% - 48px);
    height: 228px;
    background-color: $brown;
    // margin-bottom: 48px;
    padding: 40px 24px 24px 24px;
    position: relative;
    @include mixins.flex(row, space-between, flex-start);

    @media (max-width: 1260px) {
        width: 339px;
        height: auto;
        gap: 26px;
        padding: 40px 24px 124px 24px;
        @include mixins.flex(column, flex-start, flex-start);
    }
    
    @media (max-width: 500px) {
        width: calc(100% - 48px);
        gap: 16px;
    }

    .price-item-extra-title-box {
        align-self: stretch;
        @include mixins.flex(column, space-between, flex-start);

        .price-block-extra-title {

            @media (max-width: 1260px) {
                width: 100%;
                gap: 8px;
                @include mixins.flex(row, space-between, center);
            }

            h4 {
                margin: 0 0 16px 0;
                @include mixins.font(Playfair, 50px, normal, 400, 45px);

                @media (max-width: 1260px) {
                    margin: 0;
                    @include mixins.font(Playfair, 36px, normal, 400, 21.6px);
                }

                @media (max-width: 500px) {
                    @include mixins.font(Playfair, 32px, normal, 400, 21.6px);
                }
            }

            h5 {
                margin: 0;
                @include mixins.font(Forum, 18px, normal, 400, 21.6px);

                @media (max-width: 1260px) {
                //    width: 45%;
                }
            }
        }

        button {
            width: 311px;
            height: 82px;
            background-color: $transparent;
            outline: none;
            border-radius: 1000px;
            border: 1px solid $block-color;
            color: $block-color;
            cursor: pointer;
            transition: all 0.4s ease-in-out;
            @include mixins.font(Forum, 22px, normal, 400, 110%);

            @media (max-width: 1260px) {
                width: calc(100% - 48px);
                position: absolute;
                bottom: 24px;
            }

            @media (max-width: 500px) {
                height: 74px;
             }
    
            &:hover {
                background-color: $block-color;
                color: $main-color;
            }
        }
    }

    .price-block-extra-info-box {
        height: 100%;
        @include mixins.flex(column, flex-end, flex-end);

        @media (max-width: 1260px) {
            width: 100%;
        }

        .price-block-extra-info {
            margin-bottom: 26px;
            @include mixins.flex(column, flex-start, flex-start);

            span {
                min-width: 100%;
                max-width: 560px;
                padding: 12px 0px;
                border-bottom: 1px solid $line-color;
                @include mixins.font(Forum, 18px, normal, 400, 21.6px);
            }
        }


        .price-block-extra-price {
            gap: 32px;
            @include mixins.flex(row, flex-end, flex-start);

            &.hidden {
                span:nth-child(2) {
                    display: none;
                }
            }

            @media (max-width: 1260px) {
                width: 100%;
                height: 56px;
                @include mixins.flex(row, space-between, flex-start);
            }

            @media (max-width: 500px) {
                position: relative;
                gap: 16px;
                height: 46px;
                @include mixins.flex(row, flex-start, flex-start);
            }

            span:first-child {
                @include mixins.font(Forum, 50px, normal, 400, 60px);

                @media (max-width: 500px) {
                    @include mixins.font(Forum, 32px, normal, 400, 100%);
                }
            }

            span:nth-child(2) {
                opacity: 0.5;
                text-decoration: line-through;
                text-decoration-thickness: 1px;
                margin-right: 15px;
                @include mixins.font(Forum, 24px, normal, 400, 120%); 

                @media (max-width: 1260px) {
                    margin-right: 25px;
                }

                @media (max-width: 500px) {
                    @include mixins.font(Forum, 12px, normal, 400, 120%);
                }
            }

            span:last-child {
                width: 71px;
                @include mixins.font(Forum, 16px, normal, 400, 120%);

                @media (max-width: 500px) {
                    position: absolute;
                    right: 0;
                    width: 53px;
                    justify-self: flex-end;
                    @include mixins.font(Forum, 12px, normal, 400, 120%);
                }
            }
        }
    }
}