@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.header {
    width: calc(100% - 40px);
    height: 67px;
    padding: 20px 20px 0 20px;
    background-color: $transparent;
    position: fixed;
    top: -1px;
    z-index: 1000;
    transition: background-color 0.7s ease-in-out, top 1s cubic-bezier(0.4, 0, 0.2, 1);
    @include mixins.flex(row, center, center);

    &.hide {
        top: -100%;
    }

    &.open {
        background-color: $block-color;
    }

    @media (max-width: 500px) {
        width: calc(100% - 32px);
        height: 58px;
        padding: 20px 16px 0 16px;
    }


    .header-container {
        width: calc(100% - 68px);
        height: calc(100% - 4px);
        max-width: calc(1400px - 68px);
        padding: 0 32px;
        background-color: $block-color;
        border-radius: 10000px;
        border: 2px solid $white;
        transition: all 0.6s ease-in-out;
        @include mixins.flex(row, space-between, center);

        &.open {
            background-color: $main-color;
            border: 2px solid $main-color;
        }

        @media (max-width: 500px) {
            width: calc(100% - 48px);
            padding: 0 24px;
        }

        a {
            color: unset;
        }

        .header-logo {
            color: $main-color;
            transition: all 0.6s ease-in-out;

            &.open {
                color: $white;
            }
        }

        .header-navigation {
            width: 512px;

            &.visible {
                @media (max-width: 850px) {
                    display: none;
                    visibility: hidden;
                }
            }

            &.open {
                // transform: translateY(0px);
                top: 87px;
                opacity: 1;
                pointer-events: all;

                @media (max-width: 500px) {
                    top: 78px;
                }
            }
        
            @media (max-width: 850px) {
                width: calc(100vw - 40px);
                height: 100vh;
                padding: 0 20px;
                background-color: $block-color;
                position: absolute;
                z-index: -5;
                top: -100%;
                left: 0;
                opacity: 0;
                pointer-events: none;
                // transform: translateY(calc(-100% - 87px));
                transition: all 1s ease-in-out;
                @include mixins.flex(column, flex-start, center);
            }

            @media (max-width: 500px) {
                width: calc(100% - 32px);
                height: 100vh;
                padding: 0 16px;
                top: -100%;
                // transform: translateY(calc(-100% - 78px));
            }


            ul {
                width: 100%;
                list-style: none;
                margin: 0;
                padding: 0;
                @include mixins.flex(row, space-between, center);

                @media (max-width: 850px) {
                    margin-top: 36px;
                    @include mixins.flex(column, flex-start, center);
                }
            }
        }


        .open-icon{
            display: none;
    
            @media (max-width: 850px) {
                display: block;
            }
        }
    
        .close-icon{
            display: none;
        }
    
        .open-icon {
            &.open {
                display: none;
            }
        }
    
        .close-icon {
            &.open {
                display: block;
            }
        }
    }
}



// Styles For Social Media Block

.navigation-socials-media-block {
    display: none;
    width: 100%;
    margin-top: 32px;

    @media (max-width: 850px) {
        @include mixins.flex(row, space-around, center);
    }

    @media (max-width: 400px) {
        @include mixins.flex(row, space-between, center);
    }

    .social-media-button {
        width: 152px;
        height: 52px;
        background-color: $main-color;
        padding: 4px 6px 4px 6px;
        border-radius: 10000px;
        cursor: pointer;
        transition: all 0.5 ease-in-out;
        position: relative;
        gap: 24px;
        @include mixins.flex(row, flex-end, center);

        div {
            width: 48px;
            height: 48px;
            border-radius: 10000px;
            background-color: $block-color;
            position: absolute;
            left: 6px;
            transition: all 0.5s ease-in-out;
            @include mixins.flex(row, flex-start, center);

            img {
                transition: all 0.3s ease-in-out;
                margin-left: 12px;
            }
        }

        span {
            color: $block-color;
            margin-right: 18px;
            position: relative;
            z-index: 3;
            transition: color 0.3s ease-in-out, margin-right 0.3s ease-in-out;
            @include mixins.font(Forum, 16px, normal, 400, 120%);
        }
    }

    .social-media-button:hover {
        @include mixins.flex(row, flex-end, center);

        span {
            color: $main-color;
            margin-right: 24px;
        }

        div {
            width: 152px;
            transition: all 0.5 ease-in-out;
            @include mixins.flex(row, flex-start, center);

            img {
                margin-left: 20px;
            }
        }

    }
}